import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import '../Styles/SignInPage.css'

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { signIn } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            await signIn(email, password)
            navigate('/')
        } catch (e) {
            setError('Sign In Failed');
            console.warn('Sign In Failed');
        }

        setLoading(false)
    }

    return (
        <>
            <Helmet>
                <title>{'L&L Detailing - SignIn'}</title>
            </Helmet>

            {/* <h1>Sign In</h1> */}

            <form className="signinform-container" onSubmit={onSubmit}>
                <div className="signinform-card">
                    <input
                        type='text'
                        className='input-email'
                        id='input-email'
                        placeholder='Email'
                        value={email} onChange={(e) => { setEmail(e.target.value) }}
                        required />

                    <input
                        type='password'
                        className='input-password'
                        id='input-password'
                        placeholder='Password'
                        value={password} onChange={(e) => { setPassword(e.target.value) }}
                        required />

                    <button
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Signing In ...' : 'Sign In'}
                    </button>
                </div>
            </form>
        </>
    )
}

export default SignIn;