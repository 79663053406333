import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../contexts/authContext';

const ProtectedRoute = ({children}) => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser === null) {
            navigate('/404NF', { replace: true });
        }
    }, [navigate, currentUser]);

    return children;
}

export default ProtectedRoute