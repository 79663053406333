import { Helmet } from 'react-helmet';

import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

import '../Styles/ContactPage.css';

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>{'L&L Detailing - Contact'}</title>
            </Helmet>

            {/* <h1>Contact</h1> */}

            <div className='contact-container'>
                <div className='contact-card'>
                    <div className='contact-item'>
                        <EmailIcon className='contact-icon' />
                        <a href='mailto:L.L.Detailing2008@gmail.com?subject=Info'>L.L.Detailing2008@gmail.com</a>
                    </div>
                    <div className='contact-item'>
                        <WhatsAppIcon className='contact-icon' />
                        <a href='https://wa.me/320475581886' target="_blank">+32 0475/58.18.86</a>
                    </div>
                    <div className='contact-item'>
                        <InstagramIcon className='contact-icon' />
                        <a href='https://www.instagram.com/l_l.detailing/' target="_blank">l_l.detailing</a>                        
                    </div>
                    <div className='contact-item'>
                        <FacebookIcon className='contact-icon' />
                        <a href='https://www.facebook.com/profile.php?id=61550050395313' target="_blank">L&L Detailing</a>                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact