import React, { useState } from "react";
import { useParams, useLoaderData, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import axios from "axios"

import { useAuth } from "../contexts/authContext.js";

import '../Styles/GalleryPage.css';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const SubGallery = () => {
    const params = useParams();
    const gallery = useLoaderData()
    const navigate = useNavigate();

    const config = require('../config.js');
    const { currentUser } = useAuth();

    const [model, setModel] = useState(false);
    const [data, setData] = useState({ img: '', index: 0 })

    let galleryItem = []
    let imagePaths = []

    gallery.data.map(item => {
        try {
            if (item.ID === params.ID) {
                galleryItem = item
                imagePaths = item.IMAGES.map(image => `https://lldetailingstorage.blob.core.windows.net/gallery-images/${image}?${config.blobSAStoken}`)
            }
        } catch (error) {
            console.log("Coudn't load requested item")
        }
    })

    const viewImage = (image, index) => {
        let img = image;
        setData({ img, index });
        setModel(true);
    }

    const imgAction = (action) => {
        let i = data.index;

        // If not on last image, go to the next
        // If on last image go to the first image
        if (action === 'next-img') {
            if (i !== (imagePaths.length - 1)) {
                setData({ img: imagePaths[i + 1], index: i + 1 })
            } else {
                setData({ img: imagePaths[0], index: 0 })
            }
        }

        // If not on first image , go to the previous
        // If on the first image, go to the last image
        if (action === 'previous-img') {
            if (i !== 0) {
                setData({ img: imagePaths[i - 1], index: i - 1 })
            } else {
                setData({ img: imagePaths[imagePaths.length - 1], index: imagePaths.length - 1 })
            }
        }
    }

    const deleteGalleryItem = async () => {
        await axios.delete(`https://${config.API}/api/gallery/${galleryItem.ID}`);
        navigate('/')
    }

    return (
        <>
            <Helmet>
                <title>{'L&L Detailing - Gallery'}</title>
            </Helmet>

            <h1 className="gradient-text">{galleryItem.TITLE}</h1>

            <div className={model ? "model open" : "model"}>
                <CloseIcon className="close" onClick={() => setModel(false)} />

                <ArrowBackIosNewIcon className="previous" onClick={() => imgAction('previous-img')} />
                <img src={data.img} alt="" />
                <ArrowForwardIosIcon className="next" onClick={() => imgAction('next-img')} />
            </div>

            <div className="gallery">
                {imagePaths.map((item, index) => {
                    return (
                        <div className="pics" key={index} onClick={() => viewImage(item, index)}>
                            <img src={item} style={{ width: '100%' }} loading="lazy" alt="" key={index} />
                        </div>
                    )
                })}
            </div>

            {(currentUser !== null) &&
                <div className="delete-container">
                    <div className="delete-button" onClick={() => deleteGalleryItem()}>
                        <DeleteForeverIcon className="delete-icon" />
                    </div>
                </div>
            }
        </>
    );
}
export default SubGallery