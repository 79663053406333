import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase";

const authContext = React.createContext()

export function useAuth() {
    return useContext(authContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()

    function signIn(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function signOut() {
        return auth.signOut()
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        signIn,
        signOut
    }

    return (
        <authContext.Provider value={value}>
            {children}
        </authContext.Provider>
    )
}