import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 

const config = require('./config.js');

// Initialize Firebase
const app = firebase.initializeApp({
  apiKey: config.firebaseApiKey,
  authDomain: config.firebaseAuthDomain,
  projectId: config.firebaseProjectId,
  storageBucket: config.firebaseStorageBucket,
  messagingSenderId: config.firebaseMessagingSenderId,
  appId: config.firebaseAppId,
  measurementId: config.firebaseMeasurementId
});

export const auth = app.auth()
export default app