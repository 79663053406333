
import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import axios from 'axios';

import { AuthProvider } from "./contexts/authContext"

import NavigationBar from './Components/Navbar.js';
import ProtectedRoute from './Components/ProtectedRoute.js';
import PageNotFound from './Pages/NotFoundPage.js';
import Gallery from './Pages/GalleryPage.js';
import SubGallery from './Pages/SubGalleryPage.js';
import About from './Pages/AboutPage.js';
import Contact from './Pages/ContactPage.js';
import Services from './Pages/ServicesPage.js';
import NewGalleryItem from './Pages/NewGalleryItemPage.js';
import SignIn from './Pages/SignInPage.js';

import './Styles/App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const config = require('./config.js');
  const router = createBrowserRouter([
    {
      element: <NavigationBar />,
      errorElement: <PageNotFound />,
      children: [
        {
          path: '/',
          element: <Gallery />,
          loader: async () => {
            return axios.get(`https://${config.API}/api/gallery`)
          },
        },
        {
          path: '/gallery/:ID',
          element: <SubGallery />,
          loader: async () => {
            return axios.get(`https://${config.API}/api/gallery`)
          },
        },
        {
          path: '/services',
          element: <Services />,
        },
        {
          path: '/contact',
          element: <Contact />,
        },
        {
          path: '/about',
          element: <About />,
        },
        {
          path: '/new',
          element: <ProtectedRoute><NewGalleryItem /></ProtectedRoute>,
        },
        {
          path: '/admin/signin',
          element: <SignIn />,
        }
      ]
    }
  ]);

  return (
    <>
      <div data-bs-theme="">
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </div>
    </>
  );
}

export default App;
