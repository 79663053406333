import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

const PageNotFound = () => {
    const errorStyles = {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }

    return (
        <>
            <Helmet>
                <title>{'L&L Detailing - Not Found'}</title>
            </Helmet>

            <div style={errorStyles}>
                <h1 style={{ marginTop: '0', display: 'flex' }}>404 Not Found</h1>
                <Link to="/">Back To Safety</Link>
            </div>
        </>
    )
}

export default PageNotFound