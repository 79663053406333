import React from "react";
import { Helmet } from 'react-helmet';
import { Link, useLoaderData } from "react-router-dom";

import { useAuth } from "../contexts/authContext.js";

import '../Styles/GalleryPage.css';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';

const Gallery = () => {
    const gallery = useLoaderData();
    const config = require('../config.js');
    const { currentUser } = useAuth();
    const imagesList = []

    console.log(currentUser);

    gallery.data.map(item => {
        const coverImageKey = `../../public/Gallery/${item.DIR}/${item.COVER_NAME}`;
        imagesList.push(coverImageKey)
    })

    return (
        <>
            <Helmet>
                <title>{'L&L Detailing - Gallery'}</title>
            </Helmet>

            {/* <h1>Gallery</h1> */}

            <div className="gallery">
                {gallery.data.map((item, index) => {
                    return (
                        <>
                            <Link to={`/gallery/${item.ID}`}>
                                <div className="pics" key={index}>
                                    <img src={`https://lldetailingstorage.blob.core.windows.net/gallery-images/${item.COVER_NAME}?${config.blobSAStoken}`} loading="lazy" style={{ width: '100%' }} alt="" key={index} />
                                </div>
                            </Link>
                        </>
                    )
                })}
            </div>

            {(currentUser !== null) &&
                <Link className="add-container" to={`/new`}>
                    <Tooltip title="Add New">
                        <div className="add-button">
                            <AddIcon className="add-icon" />
                        </div>
                    </Tooltip>
                </Link>
            }
        </>
    );
}
export default Gallery

