import React, { useState } from "react";
import { NavLink, Link, Outlet, useLocation  } from "react-router-dom";

// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';

import { useAuth } from "../contexts/authContext.js";

import '../Styles/Navbar.css';
import logo_bg from '../Assets/L&L_Detailing_Horizontal_Transparant.png'
import logo_sm from '../Assets/L&L_Detailing_Icon_Transparent.png'
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

import Dropdown from 'react-bootstrap/Dropdown';

const NavigationBar = () => {
    const [menu, setMenu] = useState(false)
    const { currentUser, signOut } = useAuth();
    const location = useLocation();

    const handleClick = async () => {
        try {
            await signOut();
        } catch (e) {
            console.error(e);
        }
    }

    const getTitle = (pathname) => {
        switch (pathname) {
            case '/':
                return 'Gallery';
            case '/services':
                return 'Services';
            case '/contact':
                return 'Contact';
            case '/about':
                return 'About';
            case '/admin/signin':
                return 'Sign In';
            case '/new':
                return 'New';
            default:
                return '';
        }
      };

    return (
        <>
            <nav>
                <Link to={`/`}>
                    <div className='logo_bg'>
                        <img src={logo_bg} alt='' />
                    </div>
                    <div className='logo_sm'>
                        <img src={logo_sm} alt='' />
                    </div>
                </Link>

                <div className="title">
                    <h1 className="gradient-text">{getTitle(location.pathname)}</h1>
                </div>                

                <div className="menu" onClick={() => {
                    setMenu(!menu)
                }}>
                    <MenuIcon className="menu-icon" /> 
                </div>

                <ul className={menu ? 'open' : ''}>
                    <li><NavLink to={`/`} className='gradient-text' onClick={() => {setMenu(!menu)}}>Gallery</NavLink></li>
                    <li><NavLink to={`/about`} className='gradient-text' onClick={() => {setMenu(!menu)}}>About</NavLink></li>
                    <li><NavLink to={`/services`} className='gradient-text' onClick={() => {setMenu(!menu)}}>Services</NavLink></li>
                    <li><NavLink to={`/contact`} className='gradient-text' onClick={() => {setMenu(!menu)}}>Contact</NavLink></li>
                    {/* {(currentUser !== null) &&
                        <li><button onClick={handleClick}>Sign Out</button></li>
                    } */}

                    {(currentUser !== null) &&
                        <li>
                            <Tooltip title="Sign Out">
                                <div className="signout" onClick={handleClick}>
                                    <LogoutIcon className="signout-icon"></LogoutIcon>
                                </div>
                            </Tooltip>
                        </li>
                    }

                    {/* <li><AccountCircleOutlinedIcon /></li>
                    <li>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Dropdown Button
                            </Dropdown.Toggle>

                            <Dropdown.Menu>   
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li> */}
                </ul>

            </nav>

            <Outlet />
            <footer></footer>
        </>
    )
}
export default NavigationBar