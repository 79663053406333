const config = {}

config.API = 'lldetailing-backend.azurewebsites.net'

config.blobSAStoken = 'sp=r&st=2024-10-11T16:36:18Z&se=2024-10-17T00:36:18Z&spr=https&sv=2022-11-02&sr=c&sig=8L0YSraudFDZPv2XiRHQ%2FerOn12l9ZCqe7JRCeSZr2I%3D'

config.firebaseApiKey = 'AIzaSyBPd8b8ZDF0tAu5t3Oz1LdZsHjJfOJX34E'
config.firebaseAuthDomain = 'lldetailing-6034b.firebaseapp.com'
config.firebaseProjectId = 'lldetailing-6034b'
config.firebaseStorageBucket = 'lldetailing-6034b.appspot.com'
config.firebaseMessagingSenderId = '204859387858'
config.firebaseAppId = '1:204859387858:web:41d527a52a837d1838620b'
config.firebaseMeasurementId = 'G-7GTJLJP3E0'

module.exports = config;