import { Helmet } from 'react-helmet';

const Services = () => {
    return (
        <>
            <Helmet>
                <title>{'L&L Detailing - Services'}</title>
            </Helmet>

            {/* <h1>Services</h1> */}
        </>
    )
}
export default Services