import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useForm } from "react-hook-form";
import axios from "axios"
import { v4 as uuid } from 'uuid'

import '../Styles/NewGalleryItemPage.css';
import UploadIcon from '@mui/icons-material/Upload';

const NewGalleryItem = () => {
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();
    const [files, setFiles] = useState(null);
    const [progress, setProgress] = useState({ started: false, pc: 0 });
    const [message, setMessage] = useState(null);
    const [uploadSucces, setUploadSucces] = useState(false);

    const config = require('../config.js');
    const navigate = useNavigate();
    const newID = uuid()

    const onSubmit = async (data) => {
        // await new Promise((resolve) => setTimeout(resolve, 2000));

        const nameData = [];
        for (let i = 0; i < files.length; i++) {
            nameData.push(files[i].name);
        }

        const jsonData = {
            "TITLE": data.title,
            "DISCRIPTION": data.discription,
            "DIR": data.title.split(' ').join('_'),
            "COVER_NAME": data.coverName,
            "IMAGES": nameData
        };

        await axios.post(`https://${config.API}/api/gallery/${newID}`, {
            data: jsonData,
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => console.log(res))
            .then(res => navigate('/'))
            .catch(error => console.log(error))
    }

    const handleUpload = async () => {
        if (!files) {
            setMessage('No Images Selected');
            return false;
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(`file${i + 1}`, files[i]);
        }

        setMessage('Uploading...');
        setProgress(prevState => {
            return { ...prevState, started: true }
        })

        axios.post(`https://${config.API}/api/upload/${newID}`, formData, {
            onUploadProgress: (progressEvent) => {
                setProgress(prevState => {
                    return { ...prevState, pc: progressEvent.progress * 100 }
                })
            }
        })
            .then(res => {
                setMessage('Upload Succesful');
                setUploadSucces(true);
                console.log(res.data);
                return true;
            })
            .catch(error => {
                setMessage('Upload Failed');
                console.error(error);
                return false;
            });
    }

    return (
        <>
            <Helmet>
                <title>{'L&L Detailing - New'}</title>
            </Helmet>

            {/* <h1>Add Gallery</h1> */}

            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-card">
                    <div className="form-card-left">
                        <label htmlFor="fileupload" className="fileupload">
                            <input {...register('images', {
                                required: 'Images are required'
                            })} type="file" placeholder="Images" onChange={(e) => { setFiles(e.target.files) }} id="fileupload" hidden multiple />
                            {files === null ? "Select Images" : `${files.length} Images Selected`}
                        </label>

                        {errors.images && <div style={{ color: 'red' }}>{errors.images.message}</div>}
                        {progress.started && <progress max='100' value={progress.pc} className="upload-progress"></progress>}

                        <div className="fileupload-button-container">
                            <button className="fileupload-button" type="button" onClick={handleUpload}><UploadIcon className="fileupload-icon" />Upload Images</button>
                            {message && <span style={{ marginTop: '.5em' }}>{message}</span>}
                        </div>
                    </div>

                    <div className="form-card-right">
                        <input {...register('title', {
                            required: 'Title is required'
                        })} type="text" placeholder="Title" />
                        {errors.title && <div style={{ color: 'red' }}>{errors.title.message}</div>}

                        <textarea {...register('discription')} type="text" placeholder="Discription" />

                        <input {...register('coverName', {
                            required: 'Cover Image Name is required',
                        })} type="text" placeholder="Cover Image Name" />
                        {errors.coverName && <div style={{ color: 'red' }}>{errors.coverName.message}</div>}

                        <button disabled={isSubmitting || !uploadSucces} type="submit">{isSubmitting ? "Saving..." : "Save"}</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default NewGalleryItem
