import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';

const About = () => {
    return (
        <>
            <Helmet>
                <title>{'L&L Detailing - About'}</title>
            </Helmet>

            {/* <h1>About</h1> */}
        </>
    )
}
export default About